@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.GraphicPromotion {
  display: block;

  &__disclaimer {
    margin-top: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-base();
    }
  }
}
